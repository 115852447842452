var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { full: "" } }, [
    _c("div", { staticClass: "operation" }, [
      _c("span", { staticClass: "cancel", on: { click: _vm.cancel } }, [
        _vm._v(_vm._s(_vm.$t("lang.cancel"))),
      ]),
      _c("span", [_vm._v(_vm._s(_vm.$t("lang.newPost")))]),
      _c("span", { staticClass: "send", on: { click: _vm.send } }, [
        _vm._v(_vm._s(_vm.$t("lang.share"))),
      ]),
    ]),
    _c(
      "div",
      [
        _c("van-field", {
          attrs: {
            placeholder: _vm.$t("lang.shareTips"),
            type: "textarea",
            autosize: "",
          },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <layout full>
    <div class="operation">
      <span class="cancel" @click="cancel">{{ $t('lang.cancel') }}</span>
      <span>{{ $t('lang.newPost') }}</span>
      <span class="send" @click="send">{{ $t('lang.share') }}</span>
    </div>
    <div>
      <van-field v-model="content" :placeholder="$t('lang.shareTips')" type="textarea" autosize />
    </div>
  </layout>
</template>

<script>
import { Field } from 'vant'
import common from '@/utils/common'
export default {
  name: "Community",
  data() {
    return {
      content: ''
    }
  },
  components: {
    [Field.name]: Field
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    send() {
      let params = {
        "createTime": Math.round(new Date()),
        "description": this.content,
        "tags": [],
        'mention': [],
        "photos": [],
      }

      const txData = common.getTxData({
        nodeName: 'SimplePhotoShare',
        brfcId: '88a4a0bdebcb',
        path: '/Protocols/SimplePhotoShare',
        data: JSON.stringify(params),
        attachments: []
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.$toast(this.$t('lang.success'))
        this.$router.back()
      }, (err) => {

      })
    }
  }
}
</script>

<style lang="less" scoped>
  .operation {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #C4C5C6;
    .cancel {
      cursor: pointer;
    }
    .send {
      font-size: 14px;
      color: #FFFFFF;
      background-color: #617FFF;
      width: 60px;
      height: 30px;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
    }
  }
  /deep/.van-field__control {
    font-size: 18px;
  }
</style>